<template>
  <v-container class="pa-0 main_lobby" fluid>
    <vendor-booth-image />
    <VendorDirectory />
    <v-speed-dial bottom fixed class="mb-16" right>
    <template v-slot:activator>
      <v-btn dark @click="showVendorDirectory" depressed color="orange">
        <v-icon left>mdi-view-list-outline</v-icon> {{$t('common.vendor_directory_btn')}}
      </v-btn>
    </template>
    </v-speed-dial>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import axios from "@/helper/axios";
import VendorBoothImage from "@/components/vendor-hall/VendorBoothImage";
import VendorDirectory from "@/components/exhibitor/Directory.vue";
export default {
  components: {
    VendorBoothImage,
    VendorDirectory
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
    "$route.params.room_id": function () {
      this.addBallroomView();
    },
  },
  methods : {
    ...mapMutations("vendor_directory", ["setVendorData"]),
    showVendorDirectory() {
      this.setVendorData({
        key: "show_vendor_directory_modal",
        data: true,
      });
    },
    addBallroomView() {
      let _self = this;
      axios
        .post("/room/add_view",{room_id: this.$route.params.room_id})
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
  },
  mounted() {
    this.addBallroomView();
  },
}
</script>
<style>
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00000040 !important;
}
</style>
